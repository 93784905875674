<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    @getData="importAttendanceData(null, $event)"
    :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
  >
    <div class="form-group mb-0 break-spaces">
      <TextArea
        id="anamnese"
        :value="form.fields.anamnese"
        @blur="updateMedicalRecord(form)"
        @inputTextArea="el => updateForm('anamnese', el)"
        :readonly="!canEdit"
        rows="1"
        placeholder="Descrever"
      />
    </div>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    TextArea: () => import('@/components/General/TextArea'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.anamnese,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/anamnese', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records

      const attendanceData = data.find(el => { return el.type === 'pre-anamnese'})
      if (!attendanceData?.id) return
      if (attendanceData.value.preAnamnese) 
        this.updateForm('anamnese', attendanceData.value.preAnamnese)
      await this.updateMedicalRecord(this.form)
    },
  }
}
</script>
